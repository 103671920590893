<template>
  <apexchart
    type="bar"
    :height="250"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
export default {
  name: "ClinicianBarChart",

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  data() {
    const defaultOptions = {
      chart: {
        width: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: this.data.map((item) => item.color),
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "right",
        fontSize: "14px",
        itemMargin: {
          vertical: 10,
          horizontal: 5,
        },
        markers: {
          radius: 10,
        },
      },
      tooltip: {
        custom({ dataPointIndex, w }) {
          return (
            `${
              '<div class="custom-tooltip">' +
              `<span class="marker" style="background-color: ${w.config.colors[dataPointIndex]};"></span>` +
              "<span>"
            }${w.config.xaxis.categories[dataPointIndex]}: ` +
            `</span>` +
            `<span>${w.config.series[0].data[dataPointIndex]}</span>` +
            `</div>`
          );
        },
      },
      xaxis: {
        categories: this.data.map((item) => item.category),
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "12px",
          },
          formatter: (y) => y.toFixed(0),
        },
      },
    };
    const mobileLegendOptions = {
      position: "bottom",
      itemMargin: {
        vertical: 5,
        horizontal: 5,
      },
    };

    return {
      defaultOptions,
      mobileLegendOptions,
    };
  },

  computed: {
    series() {
      const percents = this.data.map((item) => item.value);
      return [
        {
          data: percents,
        },
      ];
    },
    chartOptions() {
      return !this.$vuetify.breakpoint.mobile
        ? this.defaultOptions
        : {
            ...this.defaultOptions,
            legend: {
              ...this.defaultOptions.legend,
              ...this.mobileLegendOptions,
            },
          };
    },
  },
};
</script>

<style lang="scss">
.custom-tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.9);

  .marker {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #ffffff;
  }

  span {
    margin-left: 5px;
    color: #ffffff;
  }
}
</style>
